import { useState } from "react";
import Form from "./Form";
import "./rsvp.css";

const RSVP = () => {
  const [loading, setLoading] = useState(false);
  const submit = async (
    navn: string,
    epost: string,
    allergi: string,
    annet: string,
    tirsdag: boolean,
    onsdag: boolean,
    baby: boolean
  ) => {
    setLoading(true);

    const response = await fetch(
      "https://api.airtable.com/v0/apph97xBQOwIAQN6o/tbl4oJNXqSHBqhH8a",
      {
        headers: {
          Authorization:
            "Bearer patZb5rBNcftp71wa.4c8ac353b0fda90e572074e9d740d78bf01132df1ffcf89475428fbc9940d6fc",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          records: [
            {
              fields: {
                navn: navn,
                epost: epost,
                hotell_tirsdag: tirsdag,
                hotell_onsdag: onsdag,
                med_barn: baby,
                allergier: allergi,
                annet: annet,
              },
            },
          ],
        }),
      }
    );

    setLoading(false);

    if (response.status >= 400) {
      throw Error(
        "Noe gikk galt med fetch, error: " +
          response.status +
          " - " +
          response.body
      );
    }
  };

  return (
    <div>
      <div className="kommer-du">Kommer du?</div>
      <div className="rsvp-date">RSVP 1. mai</div>
      <Form onSubmit={submit} loading={loading} />
    </div>
  );
};

export default RSVP;
