import React from "react";
import "./kleivstua.css";
import { Fade } from "react-awesome-reveal";

const Kleivstua = () => {
  return (
    <>
      <Fade fraction={1} cascade triggerOnce>
        <div className="heading-1">Bli med på feiringen</div>
        <div className="heading-2">
          på <strong className="heading-2-strong">Kleivstua hotell</strong>
        </div>
      </Fade>

      <div className="kleivstua-bilder">
        <Fade
          cascade
          triggerOnce
          fraction={1}
          className="kleivstua-bilder-fade"
          damping={0.1}
        >
          <img
            className="kleivstua-bilde b1"
            src="oversikt.jpg"
            alt="oversikt"
          />
          <img
            className="kleivstua-bilde b2"
            src="resepsjon.jpg"
            alt="resepsjonen"
          />
          <img className="kleivstua-bilde b3" src="soverom.png" alt="soverom" />

          <div className="kleivstua-infotext">
            Vi håper dere vil overnatte sammen med oss på Kleivstua utenfor
            Hønefoss. Dere velger selv hvilke dager, og prisen ligger på ca.{" "}
            <strong>800kr</strong> per person per natt.
          </div>
        </Fade>
      </div>
    </>
  );
};

export default Kleivstua;
