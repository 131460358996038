import React from "react";
import "./save-the-date.css";

const SaveTheDate = () => {
  return (
    <div className="card-and-chevron">
      <div className="kort-header">Mathilde & Kristian ♡</div>
      <div className="">09.07.2024</div>

      <img src="forside.jpg" alt="Bilde av oss to" className="kort" />
      <div style={{ fontSize: "0.75rem" }}>
        Fotostorie Taran Gjøystdal/ @fotostorie.no
      </div>

      <div className="chevron-dip">
        <img src="chevron.png" alt="pil ned" className="chevron" />
      </div>
    </div>
  );
};

export default SaveTheDate;
