import { Fade } from "react-awesome-reveal";
import "./toastmaster.css";

const Toastmaster = () => {
  return (
    <Fade
      triggerOnce
      cascade
      fraction={1}
      damping={0.1}
      className="toastmaster"
    >
      <div className="toastmaster-heading-1 text-bold">Toastmaster Aurora</div>
      <div className="toastmaster-heading-2">
        loser oss trygt gjennom kvelden
      </div>
      <img src="aurora.jpg" className="aurora" alt="aurora" />
      <div className="contact-info">
        Vil du holde tale? Ta kontakt med Aurora på <br />
        aurora.brun@bekk.no
        <br />
        Tlf. 454 86 553
      </div>
    </Fade>
  );
};

export default Toastmaster;
