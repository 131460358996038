import { useState } from "react";
import Information from "./Information";

import "./more-information.css";
import { Fade } from "react-awesome-reveal";

export enum Choice {
  OVERNATTING,
  TIRSDAG,
  GRILLING,
  GIFTS,
  DRESSCODE,
  FEST,
  BARN,
}

const allChoices = [
  Choice.OVERNATTING,
  Choice.TIRSDAG,
  Choice.GRILLING,
  Choice.GIFTS,
  Choice.DRESSCODE,
  Choice.FEST,
  Choice.BARN,
];

const choiceToTitle = (choice?: Choice) => {
  if (choice === Choice.OVERNATTING) return "Overnatting på Kleivstua";
  if (choice === Choice.GIFTS) return "Gaver";
  if (choice === Choice.TIRSDAG) return "Vielse og bursdag";
  if (choice === Choice.DRESSCODE) return "Dresscode";
  if (choice === Choice.GRILLING) return "Grilling på tirsdagen";
  if (choice === Choice.FEST) return "Bryllupsfest";
  if (choice === Choice.BARN) return "Barn + bryllup = sant?";
  return undefined;
};

const MoreInformation = () => {
  const [choice, setChoice] = useState<undefined | Choice>(undefined);

  return (
    <Fade triggerOnce cascade fraction={0.25}>
      <div className="more-information-heading">
        Okei. <strong className="text-bold">Sånn her gjør vi det!</strong>
      </div>

      <div className="more-information-content">
        <div
          className={
            "more-information-list " +
            (choice !== undefined ? " more-information-list-invisible" : "")
          }
        >
          {allChoices.map((choice) => (
            <div
              className="more-information-item"
              key={choice.toString()}
              onClick={() => setChoice(choice)}
            >
              {choiceToTitle(choice)} {">"}
            </div>
          ))}
        </div>

        {choice !== undefined ? (
          <div className="more-information-selected">
            <div className="text-bold">{choiceToTitle(choice)}</div>

            <Information choice={choice} />

            <div
              className="text-bold back-button"
              onClick={() => setChoice(undefined)}
            >
              {"<"} Tilbake
            </div>
          </div>
        ) : null}
      </div>
    </Fade>
  );
};

export default MoreInformation;
