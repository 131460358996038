import { Choice } from "./MoreInformation";

const Information = ({ choice }: { choice?: Choice }) => {
  if (choice === Choice.OVERNATTING)
    return (
      <div className="more-information-selected-textblock">
        Vi har booket alle 43 rommene på Kleivstua hotell. Hvert rom har 2-4
        sengeplasser og er plassert i hytter med 2-5 rom i hver. Booking skjer
        gjennom oss, og i utgangspunktet tenker vi å sette opp fordelingen. Om
        du kommer uten følge vil vi uansett forhøre oss med deg før vi setter
        deg på rom med noen andre.
        <br /> <br />
        De fleste rom har eget bad og hver hytte har en felles stue. Rommet er
        inkludert frokost begge dager og en baguettlunsj på onsdagen. Det er
        mulig å overnatte fra mandag for de som ønsker det. Skriv i så fall en
        melding i påmeldingen under.
        <br />
        <br />
        <strong>Tidlig innsjekk</strong>
        <br />
        Det er mulig for de av dere som reiser langt å sjekke inn før vielsen,
        dersom dere trenger rom for å gjøre dere klar. Tidlig innsjekk er fra kl
        13, men muligens tidligere om man er heldig.
        <br />
        <br />
        Følgende priser gjelder per natt og betales ved utsjekk
        <ul>
          <li>1320,- for et enkeltrom</li>
          <li>1680,- for et dobbeltrom, 840,- per pers</li>
          <li>2000,- for et tremannsrom, 660,- per pers</li>
          <li>2320,- for et firemannsrom, 580,- per pers</li>
        </ul>
        Gjester skal ikke betale bom på vei opp til Kleivstua
      </div>
    );

  if (choice === Choice.TIRSDAG)
    return (
      <div className="more-information-selected-textblock">
        Vielsen avholdes i Hole kirke kl.{" "}
        <strong style={{ fontWeight: "600" }}>14:00</strong>, kun 20 minutter
        kjøretur fra Kleivstua. Det finnes parkering rett ved kirken. Man bør
        være på plass senest 15-20 minutter før vielsen starter. Vielsen avholdes også på brudgommens bursdag, så forhåpentligvis får han livets beste gave akkurat denne dagen.
        <br />
        <br />
        Etter vielsen drar brudeparet på fotografering sammen med forlovere, og
        resten av gjestene drar til Kleivstua for innsjekk og grillfest.
        Grillfesten blir utenfor Langhuset, varer fra ca kl 18:00 - 20:00 (På
        Kleivstua) hvor det blir mulighet for å kjøpe drikke selv. Det vil også
        bli litt leker og konkurranser.
      </div>
    );

  if (choice === Choice.GRILLING)
    return (
      <div className="more-information-selected-textblock">
        Etter vielsen drar vi til Kleivstua for innsjekk, grillfest og
        bursdagsfeiring. Grillfesten blir utenfor Langhuset og det blir mulighet
        for å kjøpe drikke selv. Vi ordner grillmat som passer til innmeldte
        allergier og diettpreferanser.
        <br />
        <br />
      </div>
    );

  if (choice === Choice.DRESSCODE)
    return (
      <div className="more-information-selected-textblock">
        Dresscode. Er det så viktig, da? Vi tar det med, så slipper du å
        kontakte alle andre du kjenner som skal i bryllupet.
        <br />
        <br />
        Dresscode for grillingen er{" "}
        <strong style={{ fontWeight: "600" }}>sommerlig</strong>. Tenk at du
        skal på grillfest til en god venn! Når det er sagt, er det selvsagt lov
        å avsløre morgendagens finstas allerede her.
        <br />
        <br />
        Til bryllupsmiddagen oppgraderer vi til{" "}
        <strong style={{ fontWeight: "600" }}>pent sommerlig</strong>. I følge
        bryllupsfestivalen.no vil det si "Her passer det fint med en blomstrete
        sommerkjole til henne og gjerne en lys dress til ham". Så sleng på deg
        en fargerik kjole, en lindress, eller bare den helt vanlige go-to
        dressen du pleier. Vi er ikke så strenge på det! Det viktigste er at du
        selv føler deg fin og klar for en fest!
      </div>
    );
  if (choice === Choice.GIFTS)
    return (
      <div className="more-information-selected-textblock">
        Her finner du dessverre ingen ønskeliste fra Kitch'n eller Tilbords. Den
        største gaven vi får og kan få i anledning bryllupet vårt er en "helg"
        med dere på Kleivstua. Det at alle dere tar dere turen og har lyst til å
        feire med oss er noe av det vi setter høyest. Men det er flere som har
        etterspurt ønskeliste og tanker rundt gaver. Vi har tenkt og funnet ut
        at materialistiske ting ikke gir oss så veldig mye nå i vår situasjon.
        Det som betyr noe for oss er tiden vi har sammen og det å skape gode
        minner og opplevelser sammen.
        <br />
        <br />
        Så - det vi ønsker oss er tips og forslag til hyggelige ting å gjøre
        sammen, smått og stort, og hvis noen skulle ønske, økonomisk bidrag til
        det som for oss da kommer til å bli mange små bryllupsreiser med små
        opplevelser som lettere lar seg gjennomføre enn en lang reise et eller
        annet sted. Gaver kan vippses til Mathilde på 922 84 270 eller settes
        inn på kontonummer 8450 41 65212
        <br />
        <br />
      </div>
    );
  if (choice === Choice.FEST)
    return (
      <div className="more-information-selected-textblock">
        På onsdagen er det duket for bryllupsfest! Agendaen er ikke helt satt
        enda, men vi prøver å starte litt tidlig, slik at vi kan ta en pause i
        middagen dersom det blir nødvendig for Mathilde å samle litt krefter!
        <br />
        <br />
        Vi starter med en{" "}
        <strong style={{ fontWeight: "600" }}>kremet skogsoppsuppe</strong> til
        forrett. Til hovedrett får vi{" "}
        <strong style={{ fontWeight: "600" }}>posjert piggvar</strong>, før vi
        avslutter med en deilig{" "}
        <strong style={{ fontWeight: "600" }}>
          crème brûlée med pasjonsfruktsorbet.
        </strong>
        <br />
        <br />
        På formiddagen på onsdagen er det ikke planlagt noe opplegg fra vår
        side, men vi oppfordrer gjestene til å finne noe hyggelig selv, lade opp
        til kveldens fest, ta en tur til Hønefoss eller gå en tur i området. Det
        er mange fine turmuligheter ut i marka, som f.eks til Kongens utsikt
        eller Mørkgonga. Hotellet har også noen el-sykler til utlån for turer
        innover i marka.
      </div>
    );
  if (choice === Choice.BARN)
    return (
      <div className="more-information-selected-textblock">
        Vi ønsker i utgangspunktet at de som har mulighet for å skaffe seg
        barnevakt gjør dette. Det vil si at om du for eksempel ammer, er du
        velkommen til å ta med barnet ditt. Kryss i så fall av for dette i
        påmeldingen under.
        <br />
        <br />
        Alle barn er velkommen i vielsen!
      </div>
    );

  return null;
};

export default Information;
