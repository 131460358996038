import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SaveTheDate from "./SaveTheDate";
import Kleivstua from "./Kleivstua";
import Schedule from "./Schedule";
import Toastmaster from "./Toastmaster";
import MoreInformation from "./MoreInformation";
import RSVP from "./RSVP";
import Timeline from "./Timeline";

import "./App.css";
import CheckOutTheProgram from "./CheckOutTheProgram";
import VielseAttending from "./VielseAttending";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <SaveTheDate />

      <VielseAttending />

      <div className="kleivstua-container">
        <Kleivstua />
      </div>

      <div className="check-out-the-program-container">
        <CheckOutTheProgram />
      </div>

      <div className="schedule-container">
        <Schedule />
      </div>

      <div className="toastmaster-container">
        <Toastmaster />
      </div>

      <div className="more-information-container">
        <MoreInformation />
      </div>

      <div className="rsvp-container">
        <RSVP />
      </div>

      <div className="oss-container">
        <img src="oss.jpg" alt="Oss to" className="oss-to" />
      </div>
    </div>
  );
};

const Program = () => {
  return (
    <div className="container">
      <Timeline />
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/program" element={<Program />} />
      </Routes>
    </Router>
  );
}

export default App;
