import React from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const CheckOutTheProgram = () => {
  return (
    <Fade triggerOnce cascade fraction={1} className="check-out-the-program">
      <Link to="/program" style={{ color: "black", fontSize: "22px" }}>
        Sjekk ut <strong style={{ fontWeight: 600 }}>programmet</strong> {">"}
      </Link>
    </Fade>
  );
};

export default CheckOutTheProgram;
