import { Fade } from "react-awesome-reveal";

import "./schedule.css";

const Schedule = () => {
  return (
    <Fade triggerOnce cascade fraction={1} className="schedule">
      <div
        className="schedule-tirsdag"
        style={{ textDecoration: "strikethrough" }}
      >
        Vielse & grillfest <strong className="text-bold">tirsdag</strong>
      </div>
      <div className="schedule-onsdag">
        <strong className="text-bold">onsdag</strong> blir det bryllupsfest!
      </div>
    </Fade>
  );
};

export default Schedule;
