import { Checkbox } from "pretty-checkbox-react";
import { useState } from "react";
import "@djthoms/pretty-checkbox";

const Form = ({
  onSubmit,
  loading,
}: {
  onSubmit: (
    navn: string,
    epost: string,
    allergi: string,
    annet: string,
    tirsdag: boolean,
    onsdag: boolean,
    baby: boolean
  ) => Promise<void>;
  loading: boolean;
}) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [tuesday, setTuesday] = useState<boolean>(false);
  const [wednesday, setWednesday] = useState<boolean>(false);
  const [baby, setBaby] = useState<boolean>(false);
  const [allergies, setAllergies] = useState<string>("");
  const [other, setOther] = useState<string>("");
  const [result, setResult] = useState<undefined | string>(undefined);
  const [didTrySubmit, setDidTrySubmit] = useState<boolean>(false);

  const submit = async () => {
    setDidTrySubmit(true);
    if (name === "" || email === "") {
      return;
    }

    try {
      await onSubmit(name, email, allergies, other, tuesday, wednesday, baby);
      setResult("SUCCESS");
    } catch (error) {
      console.log("Noe gikk galt", error);
      setResult("ERROR");
    }
  };

  const reset = () => {
    setName("");
    setEmail("");
    setTuesday(false);
    setWednesday(false);
    setBaby(false);
    setAllergies("");
    setOther("");

    setResult(undefined);
    setDidTrySubmit(false);
  };

  const disabled = loading || result === "SUCCESS" || result === "ERROR";

  return (
    <form
      className="rsvp-form"
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <input
        disabled={disabled}
        className={
          "rsvp-input " +
          (didTrySubmit && name.length === 0 ? "input-error" : "")
        }
        placeholder="Fullt navn"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        disabled={disabled}
        className={
          "rsvp-input " +
          (didTrySubmit && email.length === 0 ? "input-error" : "")
        }
        placeholder="Epostadresse"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Checkbox
        disabled={disabled}
        bigger
        state={tuesday}
        onChange={() => setTuesday(!tuesday)}
        animation="smooth"
        className="rsvp-checkbox"
        checked={tuesday}
      >
        Jeg ønsker hotell <strong>tirsdag-onsdag</strong>
      </Checkbox>
      <Checkbox
        disabled={disabled}
        bigger
        state={wednesday}
        checked={wednesday}
        onChange={() => setWednesday(!wednesday)}
        animation="smooth"
        className="rsvp-checkbox"
      >
        Jeg ønsker hotell <strong>onsdag-torsdag</strong>
      </Checkbox>
      <Checkbox
        disabled={disabled}
        bigger
        state={baby}
        checked={baby}
        onChange={() => setBaby(!baby)}
        animation="smooth"
        className="rsvp-checkbox"
      >
        Jeg må reise med barn
      </Checkbox>

      <input
        disabled={disabled}
        className="rsvp-input rsvp-margin-top"
        placeholder="Allergier eller preferanser"
        value={allergies}
        onChange={(e) => setAllergies(e.target.value)}
      />
      <input
        disabled={disabled}
        className="rsvp-input"
        placeholder="Noe annet vi bør vite om?"
        value={other}
        onChange={(e) => setOther(e.target.value)}
      />

      <div className="margin-bottom-1rem">
        {result === "SUCCESS"
          ? "Du er påmeldt!"
          : result === "ERROR"
          ? "Uffda. Noe gikk galt! Kontakt brudeparet."
          : null}
      </div>
      {result === "SUCCESS" ? (
        <button
          className="rsvp-button"
          onClick={(e) => {
            e.preventDefault();
            reset();
          }}
        >
          Meld på en gjest til
        </button>
      ) : result === "ERROR" ? (
        <button
          className="rsvp-button"
          onClick={(e) => {
            e.preventDefault();
            reset();
          }}
        >
          Start forfra igjen
        </button>
      ) : (
        <button className="rsvp-button" disabled={disabled}>
          Send inn
        </button>
      )}
    </form>
  );
};

export default Form;
