import React, { useState } from "react";
import "./rsvp.css";
import VielseForm from "./VielseForm";

const VielseAttending = () => {
  const [loading, setLoading] = useState(false);

  const submit = async (navn: string) => {
    setLoading(true);

    const response = await fetch(
      "https://api.airtable.com/v0/apph97xBQOwIAQN6o/tblBeXoBle4NDOySh",
      {
        headers: {
          Authorization:
            "Bearer patZb5rBNcftp71wa.4c8ac353b0fda90e572074e9d740d78bf01132df1ffcf89475428fbc9940d6fc",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          records: [
            {
              fields: {
                navn: navn,
              },
            },
          ],
        }),
      }
    );

    setLoading(false);

    if (response.status >= 400) {
      throw Error(
        "Noe gikk galt med fetch, error: " +
          response.status +
          " - " +
          response.body
      );
    }
  };

  return (
    <div style={{ marginBottom: "15rem" }}>
      <div className="kommer-du">Kommer du i vielsen 26. juni?</div>
      <VielseForm onSubmit={submit} loading={loading} />
    </div>
  );
};

export default VielseAttending;
