import React, { useEffect } from "react";

import "./timeline.css";
import { Link } from "react-router-dom";

const Day = ({ title }: { title: String }) => {
  return (
    <div className="day-title">
      <p className="timeline-day" style={{ fontWeight: "600" }}>
        {title}
      </p>
    </div>
  );
};

const Event = ({
  time,
  header,
  description,
}: {
  time: string;
  header: string;
  description?: string;
}) => {
  return (
    <div className="event-wrapper">
      <div className="event-info-wrapper">
        <div className="event-time" style={{ fontWeight: "600" }}>
          {time}
        </div>
        <div className="event-header">{header}</div>
      </div>
      {description && <div className="event-description">{description}</div>}
    </div>
  );
};

const Timeline = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="timeline-wrapper">
      <h1 className="programmet-header">Programmet</h1>
      <div className="more-information-selected-textblock">
        {/** ---------- TIRSDAG ---------- **/}
        <Day title="Tirsdag" />
        <Event
          time="12:00"
          header="Tidlig innsjekk"
          description="Kleivstua vil prøve å gi tidlig innsjekk fra klokken 12, dersom noen behøver det"
        />
        <Event
          time="14:00"
          header="Vielse i Hole Kirke"
          description="Møt gjerne opp tjue minutter før. Etter vielsen drar brudeparet videre til fotografering, mens alle andre drar til Kleivstua"
        />
        <Event
          time="15:00"
          header="Innsjekk på Kleivstua"
          description="Oppgi navnet deres i resepsjonen"
        />
        <Event
          time="18:00"
          header="Grillbuffet og bursdagsfeiring"
          description="Vi feirer vielse og Kristians bursdag med grillbuffet fra 18-20. Mulighet for å kjøpe egen drikke i baren"
        />
        <Event time="00:00" header="Baren stenger" />
        {/** ---------- ONSDAG ---------- **/}
        <Day title="Onsdag" />
        <Event time="08:00" header="Frokost i hovedhuset" description="" />
        <Event
          time="10:00"
          header="Egentid"
          description="Fritid! Da kan du f.eks. benytte deg av fine turmuligheter i nærområdet, få unnagjort litt jobbing eller leie el-sykler for 500kr pr stk."
        />
        <Event
          time="13:00"
          header="Baguettlunsj"
          description="Plukkes opp i hovedhuset og nytes hvor du selv ønsker"
        />
        <Event time="16:00" header="Aperitif utenfor hovedhuset" />
        <Event
          time="16:30"
          header="Velkommen til bords!"
          description="Bryllupsmiddag i hovedhuset. Dresscode til middagen er pent sommerlig"
        />
        <Event
          time="18:00"
          header="Timeout"
          description="Vi tar en timeout på cirka én time. Mulighet for å strekke på bena og ta en tur på rommet for de som ønsker"
        />
        <Event time="19:00" header="Middagen fortsetter" description="" />
        <Event time="21:00" header="Kaffe og te" description="" />
        <Event
          time="22:00"
          header="Kaker, brudevals og dansegulv!"
          description=""
        />
        {/** ---------- TORSDAG ---------- **/}
        <Day title="Torsdag" />
        <Event time="09:00" header="Frokost i hovedhuset" description="" />
        <Event
          time="11:00"
          header="Utsjekk"
          description="Rommet betales ved utsjekk"
        />

        <Link className="text-bold back-button" to="/">
          {"<"} Tilbake
        </Link>
      </div>
    </div>
  );
};

export default Timeline;
