import React, { useState } from "react";

const VielseForm = ({
  onSubmit,
  loading,
}: {
  onSubmit: (navn: string) => Promise<void>;
  loading: boolean;
}) => {
  const [name, setName] = useState<string>("");
  const [result, setResult] = useState<undefined | string>(undefined);
  const [didTrySubmit, setDidTrySubmit] = useState<boolean>(false);

  const submit = async () => {
    setDidTrySubmit(true);
    if (name === "") {
      return;
    }

    try {
      await onSubmit(name);
      setResult("SUCCESS");
    } catch (error) {
      console.log("Noe gikk galt", error);
      setResult("ERROR");
    }
  };

  const reset = () => {
    setName("");
    setResult(undefined);
    setDidTrySubmit(false);
  };

  const disabled = loading || result === "SUCCESS" || result === "ERROR";

  return (
    <form
      className="rsvp-form"
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <input
        disabled={disabled}
        className={
          "rsvp-input " +
          (didTrySubmit && name.length === 0 ? "input-error" : "")
        }
        placeholder="Fullt navn"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className="margin-bottom-1rem">
        {result === "SUCCESS"
          ? "Du er påmeldt!"
          : result === "ERROR"
            ? "Uffda. Noe gikk galt! Kontakt brudeparet."
            : null}
      </div>
      {result === "SUCCESS" ? (
        <button
          className="rsvp-button"
          onClick={(e) => {
            e.preventDefault();
            reset();
          }}
        >
          Meld på en gjest til
        </button>
      ) : result === "ERROR" ? (
        <button
          className="rsvp-button"
          onClick={(e) => {
            e.preventDefault();
            reset();
          }}
        >
          Start forfra igjen
        </button>
      ) : (
        <button className="rsvp-button" disabled={disabled}>
          Meld på
        </button>
      )}
    </form>
  );
};

export default VielseForm;
